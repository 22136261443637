














import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class extends Vue {

  showImage = false;
  src = ""

  mounted()
  {
    setTimeout( () => { new Audio('/narration/Singing2.mp3').play(); }, 2000 )
    setTimeout( () => { new Audio('/audio/HappyBirthday1.mp3').play(); }, 31000 )
    setTimeout( () => { new Audio('/narration/Singing3.mp3').play(); }, 41000 )
    setTimeout( () => { new Audio('/audio/HappyBirthday2.mp3').play(); }, 64000 )
    setTimeout( () => { new Audio('/narration/Singing4.mp3').play(); }, 87000 )
    setTimeout( () => { new Audio('/audio/HappyBirthday3.mp3').play(); }, 93000 )
    setTimeout( () => { new Audio('/narration/Singing5.mp3').play(); }, 114000 )
    setTimeout( () => { this.OnCompleted(); }, 119000 )

    setTimeout( () => { this.src = "/images/Impossible_Mission.gif"; this.showImage = true; }, 55000 )
    setTimeout( () => { this.showImage = false }, 113000 )

    //setTimeout( () => { this.showImage = false }, 4000 )
    //setTimeout( () => { this.showImage = true; this.src = "/images/induction.png" }, 16000 )

  }

  OnCompleted()
  {
    this.$emit('completed')
  }
}
