














import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class extends Vue {

  showImage = true;
  src = "/images/Peter.png"

  mounted()
  {
    //@ts-ignore
    setTimeout( () => { new Audio('/narration/Singing1.mp3').play(); }, 2500 )
    setTimeout( () => { this.OnCompleted(); }, 13000 )

    setTimeout( () => { this.showImage = false }, 4000 )
  }

  OnCompleted()
  {
    this.$emit('completed')
  }
}
