














import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class extends Vue {

  showImage = true;
  src = "/images/Peter.png"

  mounted()
  {
    setTimeout( () => { new Audio('/narration/Intro.mp3').play(); }, 2000 )
    setTimeout( () => { this.OnCompleted(); }, 29000 )

    setTimeout( () => { this.showImage = false }, 4000 )
    setTimeout( () => { this.showImage = true; this.src = "/images/induction.png" }, 16000 )

  }

  OnCompleted()
  {
    this.$emit('completed')
  }
}
