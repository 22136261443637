













































import { Component, Vue } from 'vue-property-decorator';

//@ts-ignore
import Start from '@/views/Start.vue';
//@ts-ignore
import Intro from '@/views/Intro.vue';
//@ts-ignore
import HappyVantariDay from '@/views/HappyVantariDay.vue';
//@ts-ignore
import OldSong from '@/views/OldSong.vue';
//@ts-ignore
import Singing from '@/views/Singing.vue';
//@ts-ignore
import Outro from '@/views/Outro.vue';

@Component({
  components: {
    Start,
    Intro,
    OldSong,
    HappyVantariDay,
    Singing,
    Outro,
  },
})
export default class extends Vue {

  shown = true;
  comp = "Start"

  OnCompleted()
  {
    this.shown = false;
    if( this.comp === "Start" ) this.TransitionTo( "Intro" );
    else if( this.comp === "Intro" ) this.TransitionTo( "OldSong" );
    else if( this.comp === "OldSong" ) this.TransitionTo( "HappyVantariDay" );
    else if( this.comp === "HappyVantariDay" ) this.TransitionTo( "Singing" );
    else if( this.comp === "Singing" ) this.TransitionTo( "Outro" );
    else if( this.comp === "Outro" ) this.TransitionTo( "Start" );
  }

  TransitionTo( comp : string ) {
    setTimeout( () => { this.comp = comp; this.shown = true; }, 2000 )
  }
}
