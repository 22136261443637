














import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class extends Vue {

  showImage = false;
  src = ""

  mounted()
  {
    setTimeout( () => { new Audio('/narration/Outro.mp3').play(); }, 3000 )

    setTimeout( () => { this.showImage = true; this.src = "/images/Peter.png" }, 3000 )
    setTimeout( () => { this.showImage = false }, 5000 )
    setTimeout( () => { this.showImage = true; this.src = "/images/FridayFun.png" }, 8000 )
    setTimeout( () => { this.showImage = false }, 11000 )

    setTimeout( () => { this.showImage = true; this.src = "/images/founders_alpha.png" }, 13000 )
    setTimeout( () => { this.showImage = false }, 15000 )

    setTimeout( () => { this.showImage = true; this.src = "/images/crew.png" }, 17000 )
    setTimeout( () => { this.showImage = false }, 20000 )

    setTimeout( () => { this.showImage = true; this.src = "/images/vantari.png" }, 23000 )

    setTimeout( () => { this.OnCompleted(); }, 36000 )


  }

  OnCompleted()
  {
    this.$emit('completed')
  }
}
