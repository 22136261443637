



















import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class extends Vue {

  showImage = true;

  mounted()
  {
    //@ts-ignore
    setTimeout( () => { this.$refs["video"].play(); }, 2000 )
    setTimeout( () => { this.OnCompleted(); }, 29000 )
  }

  OnCompleted()
  {
    this.$emit('completed')
  }
}
